<template>
  <div>
    <v-form>
      <p class="mb-3">
        Which of the following are important to do before coming to lab? Select as many as apply.
      </p>

      <p v-for="option in optionsShuffled" :key="'pt-1-' + option.value" class="my-0 pl-6">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="pl-0 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemMcMPL4_Q4',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {
          text: 'Eat a solid meal',
          value: 'eat',
        },
        {
          text: "Think about the lab and ask questions about anything you don't understand",
          value: 'think',
        },
        {
          text: 'Read the lab manual',
          value: 'read',
        },
        {
          text: "Get a good night's sleep",
          value: 'sleep',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
